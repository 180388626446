import classNames from 'classnames';
import React from 'react';

import Hidden from '@components/common/Hidden';
import SwipeableDesktop from '@components/pages/home/customСards/Desktop/desktop';
import SwipeableMobile from '@components/pages/home/customСards/Mobile/mobile';
import { IHomePageComponentDataObject } from '@interfaces/home';

export interface ICustomCardsProps {
  component: IHomePageComponentDataObject;
  className?: string;
}

const SwipeableNew: React.FunctionComponent<ICustomCardsProps> = ({ component, className }) => {
  const { show, show_in_mobile } = component.contentShow;
  return (<>
    {show_in_mobile && (
      <Hidden mdUp={true}>
        <SwipeableMobile className={className} component={component} />
      </Hidden>
    )}
    {show && (
      <Hidden mdDown={true}>
        <SwipeableDesktop className={classNames('custom-cards', className)} component={component} />
      </Hidden>
    )}
  </>);
};

export default SwipeableNew;
